<script setup lang="ts">
const year = new Date().getFullYear();
</script>

<template>
  <div>
    <footer class="py-6 text-white bg-primary-500">
      <div class="container">
        <div class="flex flex-col items-center justify-between gap-3 px-3 sm:flex-row sm:gap-0">
          <p>{{ year }} © CareerPass</p>
          <a target="_blank" href="/terms-condition">Terms & Conditions</a>
        </div>
      </div>
    </footer>
  </div>
</template>
